import React, { useState } from 'react';
import './partner-merchants.component.css';
import { FaTrash } from 'react-icons/fa';

const PartnerMerchants = ({ clients, onClientChange, onDeleteClient }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [clientsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;

  const totalPages = Math.ceil(clients.length / clientsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  // Filter clients to only show those with partners
  const filteredClients = clients.filter(
    (client) =>
      client.partner &&
      (client.merchantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.merchantID.toString().includes(searchTerm))
  );

  const displayedClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

  return (
    <div className="merchants-table">
      <div className="search-bar">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by merchant name or ID..."
          className="search-input"
        />
        <p>Total Partner Merchants: {filteredClients.length}</p>
      </div>

      <table>
        <thead>
          <tr>
            <th>Merchant ID</th>
            <th>Merchant Name</th>
            <th>Partner</th>
            <th>Partner Split (%)</th>
            <th>Branch ID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {displayedClients.map((client) => (
            <tr key={client.merchantID}>
              <td>
                <input type="text" value={client.merchantID || ''} readOnly />
              </td>
              <td>
                <input
                  type="text"
                  value={client.merchantName || ''}
                  onChange={(e) =>
                    onClientChange(client.merchantID, 'merchantName', e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={client.partner || ''}
                  onChange={(e) =>
                    onClientChange(client.merchantID, 'partner', e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={client.partnerSplit || ''}
                  onChange={(e) =>
                    onClientChange(client.merchantID, 'partnerSplit', e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={client.branchID || ''}
                  onChange={(e) =>
                    onClientChange(client.merchantID, 'branchID', e.target.value)
                  }
                />
              </td>
              <td>
                <button
                  className="delete-btn"
                  onClick={() => onDeleteClient(client.merchantID)}
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-arrow"
        >
          &laquo;
        </button>

        {currentPage > 1 && (
          <button onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</button>
        )}

        <button className="active">{currentPage}</button>

        {currentPage < totalPages && (
          <button onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</button>
        )}

        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-arrow"
        >
          &raquo;
        </button>
      </div>
    </div>
  );
};

export default PartnerMerchants;
