
import React, { useEffect, useState } from 'react';
import './agent-viewer.page.css';
import { useParams } from 'react-router-dom';

// Import components
import AgentDetails from '../../../components/agents/agent-details/agent-details.component';
import AgentMerchants from '../../../components/agents/agent-merchants/agent-merchants.component';
import PartnerMerchants from '../../../components/agents/partner-merchants/partner-merchants.component';
import { getAgent, updateAgent } from '../../../api/agents.api';

const AgentViewerPage = ({ organizationID, authToken }) => {
    const { agentID } = useParams();
    const [agent, setAgent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [clients, setClients] = useState([]); // All clients stored here
    const [activeTab, setActiveTab] = useState('merchants'); // Track the active tab

    // Track agent form changes locally
    const [editedAgent, setEditedAgent] = useState(null);

    // Fetch agent data on mount
    useEffect(() => {
        const fetchAgent = async () => {
            try {
                const response = await getAgent(organizationID, agentID, authToken);
                if (response.data?.success) {
                    setAgent(response.data.agent);
                    setEditedAgent(response.data.agent); // Initialize editable agent state
                    setClients(response.data.agent.clients || []); // Store clients
                } else {
                    setError('Agent not found.');
                }
            } catch (err) {
                setError('Failed to fetch agent details.');
            } finally {
                setLoading(false);
            }
        };

        if (organizationID && agentID && authToken) {
            fetchAgent();
        } else {
            setError('Missing necessary parameters.');
            setLoading(false);
        }
    }, [organizationID, agentID, authToken]);

    // Handle changes in the agent details component
    const handleAgentChange = (field, value) => {
        setEditedAgent((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    // Handle client changes using merchantID instead of index
    const handleClientChange = (merchantID, field, value) => {
        const updatedClients = clients.map((client) =>
            client.merchantID === merchantID
                ? { ...client, [field]: value }
                : client
        );
        setClients(updatedClients);
    };

    // Handle client deletion
    const handleDeleteClient = (merchantID) => {
        const updatedClients = clients.filter((client) => client.merchantID !== merchantID);
        setClients(updatedClients);
    };

    // Handle save operation
    const handleSaveChanges = async () => {
        try {
            await updateAgent(organizationID, agentID, {
                ...editedAgent,
                clients,
            }, authToken);
            alert('Agent and client details updated successfully!');
        } catch (err) {
            alert('Failed to update agent.');
        }
    };

    // Split clients into two categories
    const clientsWithoutPartners = clients.filter((client) => !client.partner);
    const clientsWithPartners = clients.filter((client) => client.partner);

    if (loading) return <div>Loading agent details...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="agent-view-page">
            <AgentDetails agent={editedAgent} onAgentChange={handleAgentChange} />


            <div className="save-btn-container">
                <button className="save-btn" onClick={handleSaveChanges}>
                    Save Changes
                </button>
            </div>
            <div className="tabs">
                <div
                    className={`tab ${activeTab === 'merchants' ? 'active' : ''}`}
                    onClick={() => setActiveTab('merchants')}
                >
                    Merchants
                </div>
                <div
                    className={`tab ${activeTab === 'partners' ? 'active' : ''}`}
                    onClick={() => setActiveTab('partners')}
                >
                    Partner Merchants
                </div>
            </div>

            <div className="tab-content">
                {activeTab === 'merchants' ? (
                    <AgentMerchants
                        clients={clientsWithoutPartners}
                        onClientChange={handleClientChange}
                        onDeleteClient={handleDeleteClient}
                    />
                ) : (
                    <PartnerMerchants
                        clients={clientsWithPartners}
                        onClientChange={handleClientChange}
                        onDeleteClient={handleDeleteClient}
                    />
                )}
            </div>
        </div>
    );
};

export default AgentViewerPage;