import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAgents } from '../../../api/agents.api'; // Fetch agents from the API
import './needs-approval.component.css';

const NeedsApproval = ({ reports, authToken, organizationID }) => {
  const [reportsThatNeedApproval, setReportsThatNeedApproval] = useState([]);
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken && organizationID) {
      fetchAgents(); // Fetch agents when the component mounts and when authToken/organizationID changes
    }
  }, [authToken, organizationID]);

const fetchAgents = async () => {
    try {
        const agentsResponse = await getAgents(organizationID, authToken);
        setAgents(Array.isArray(agentsResponse.agents) ? agentsResponse.agents : []);
    } catch (error) {
        console.error('Error fetching agents:', error);
        setAgents([]); // Ensure agents is an empty array if fetching fails
    }
};


  // Helper function to get the previous month and year
  const getPreviousMonthAndYear = () => {
    const date = new Date();
    const previousMonth = date.getMonth() === 0 ? 11 : date.getMonth() - 1; // Handle January (0 index)
    const year = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
    
    const monthName = new Date(year, previousMonth).toLocaleString('default', { month: 'long' });
    return { month: monthName, year };
  };

  useEffect(() => {
    // Filter reports that haven't been approved
    
    const unapprovedReports = reports.filter(report => !report.approved);
  
    // Log all unapproved reports for debugging purposes
    console.log('Unapproved Reports:', unapprovedReports);
  
    // List agentIDs of agents who already have unapproved reports
    const agentReportIDs = unapprovedReports
      .filter(report => report.type === 'agent')
      .map(report => report.agentID);
  
    console.log('Agent Report IDs with unapproved reports:', agentReportIDs);
  
    // Combine unapproved reports with missing processor data
    const updatedReports = unapprovedReports.map(report => {
      if (report.type === 'agent' && !report.processor) {
        // Find the agent by agentID
        const matchingAgent = agents.find(agent => agent.agentID === report.agentID);
        if (matchingAgent) {
          // Populate the processor field with the agent's name
          const agentFullName = `${matchingAgent.fName} ${matchingAgent.lName}`;
          report.processor = agentFullName;
        }
      }
      return report;
    });
    if (agents.length === 0) {
      // If agents haven't loaded yet, return early
      return;
    }
    // Check for agents without an unapproved report
    const agentsWithoutReports = agents.filter(agent => !agentReportIDs.includes(agent.agentID));
  
    // Convert agentsWithoutReports into a report-like object for display
    const placeholderReports = agentsWithoutReports.map(agent => {
      const fullName = `${agent.fName || 'Unknown'} ${agent.lName || ''}`.trim();
      
      const report = {
        processor: fullName, // Use agent name for display purposes
        agentID: agent.agentID, // Use agent ID for linking
        type: 'agent', // Indicate the report type as 'agent'
        approved: false, // These reports need approval
      };
  
      return report;
    });

    // Get the last month and year for summary reports
    const { month, year } = getPreviousMonthAndYear();

    // Check for a single agent summary report (only one per month)
    const agentSummaryReport = reports.find(report => report.type === 'agent_summary');
    if (!agentSummaryReport || !agentSummaryReport.approved) {
      // If no agent summary report or it's not approved, add a placeholder for it
      const agentSummaryPlaceholder = {
        processor: 'Agent Summary Report',
        type: 'agent_summary',
        approved: false,
        month: `${month} ${year}`,
      };
      updatedReports.push(agentSummaryPlaceholder);
    }

    // Check for a processor summary report (only one per month)
    const processorSummaryReport = reports.find(report => report.type === 'processor_summary');
    if (!processorSummaryReport || !processorSummaryReport.approved) {
      // If no processor summary report or it's not approved, add a placeholder for it
      const processorSummaryPlaceholder = {
        processor: 'Processor Summary Report',
        type: 'processor_summary',
        approved: false,
        month: `${month} ${year}`,
      };
      updatedReports.push(processorSummaryPlaceholder);
    }
  
    // Combine updated unapproved reports and placeholder agent reports
    setReportsThatNeedApproval([...updatedReports, ...placeholderReports]);
  }, [reports, agents]);
  


  // Pagination logic
  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = reportsThatNeedApproval.slice(indexOfFirstReport, indexOfLastReport);
  const totalPages = Math.ceil(reportsThatNeedApproval.length / reportsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Navigate to the report edit page
  const handleEditClick = (reportID) => {
    navigate(`/report/${reportID}`);
  };

  // Navigate to the agent report view
  const handleViewAgentReport = (agentID, month) => {
    navigate(`/agent-report/${agentID}?month=${month}`);
  };

  // Navigate to the agent summary report view
  const handleViewSummaryReport = (month) => {
    navigate(`/agent-summary-report?month=${month}`);
  };

  // Navigate to the processor summary report view
  const handleViewProcessorSummaryReport = (month) => {
    navigate(`/processor-summary-report?month=${month}`);
  };

  return (
    <div className="needs-approval-container">
      <h3>Reports Needing Approval</h3>
      {reportsThatNeedApproval.length > 0 ? (
        <>
          <ul className="report-list">
            {currentReports.map((report, index) => (
              <li key={index} className="report-item">
                <div>
                  {/* Display "Accounts Receivable" if type is AR, otherwise show processor or agent name */}
                  <strong>
                    {report.type === 'ar'
                      ? 'Accounts Receivable'
                      : report.type === 'agent_summary'
                      ? 'Agent Summary Report' // Display the agent summary report
                      : report.type === 'processor_summary'
                      ? 'Processor Summary Report' // Display the processor summary report
                      : report.type === 'agent'
                      ? report.processor // Display agent name for agent reports
                      : report.processor || 'Unknown Processor'}
                  </strong>
                </div>
                {report.type === 'agent_summary' ? (
                  <button
                    onClick={() => handleViewSummaryReport(report.month)}
                  >
                    Edit
                  </button>
                ) : report.type === 'processor_summary' ? (
                  <button
                    onClick={() => handleViewProcessorSummaryReport(report.month)}
                  >
                    Edit
                  </button>
                ) : report.type === 'agent' ? (
                  <button
                    onClick={() => handleViewAgentReport(report.agentID, report.month || 'current')}
                  >
                    Edit
                  </button>
                ) : (
                  <button onClick={() => handleEditClick(report.reportID)}>Edit</button>
                )}
              </li>
            ))}
          </ul>

          {/* Pagination */}
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`pagination-btn ${index + 1 === currentPage ? 'active' : ''}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      ) : (
        <p>All reports have been approved.</p>
      )}
    </div>
  );
};

export default NeedsApproval;
