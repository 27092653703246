import React, { useState, useEffect } from 'react';
import './agent-merchants.component.css';
import { FaTrash } from 'react-icons/fa';

const AgentMerchants = ({ clients, onClientChange, onDeleteClient }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [clientsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [localClients, setLocalClients] = useState([]);

  useEffect(() => {
    setLocalClients(clients);
  }, [clients]);

  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const totalPages = Math.ceil(localClients.length / clientsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleInputChange = (merchantID, field, value) => {
    const updatedClients = localClients.map((client) =>
      client.merchantID === merchantID ? { ...client, [field]: value } : client
    );
    setLocalClients(updatedClients);
  };

  const handleBlur = (merchantID) => {
    const updatedClient = localClients.find(client => client.merchantID === merchantID);
    if (updatedClient) {
      onClientChange(merchantID, 'merchantName', updatedClient.merchantName);
    }
  };

  const filteredClients = localClients.filter(
    (client) =>
      !client.partner &&
      (client.merchantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.merchantID.toString().includes(searchTerm))
  );

  const displayedClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

  return (
    <div className="merchants-table">
      <div className="search-bar">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by merchant name or ID..."
          className="search-input"
        />
        <p>Total Merchants: {filteredClients.length}</p>
      </div>

      <table>
        <thead>
          <tr>
            <th>Merchant ID</th>
            <th>Merchant Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {displayedClients.map((client) => (
            <tr key={client.merchantID}>
              <td>
                <input
                  type="text"
                  value={client.merchantID || ''}
                  readOnly
                />
              </td>
              <td>
                <input
                  type="text"
                  value={client.merchantName || ''}
                  onChange={(e) =>
                    handleInputChange(client.merchantID, 'merchantName', e.target.value)
                  }
                  onBlur={() => handleBlur(client.merchantID)}
                />
              </td>
              <td>
                <button
                  className="delete-btn"
                  onClick={() => onDeleteClient(client.merchantID)}
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-arrow"
        >
          &laquo;
        </button>

        {currentPage > 1 && (
          <button onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</button>
        )}

        <button className="active">{currentPage}</button>

        {currentPage < totalPages && (
          <button onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</button>
        )}

        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-arrow"
        >
          &raquo;
        </button>
      </div>
    </div>
  );
};

export default AgentMerchants;
