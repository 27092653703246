import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import processorTypeMap from '../../../lib/typeMap.lib.js';
import './agent-report-viewer.component.css';

const AgentReportViewer = ({
    authToken,
    organizationID,
    agentID,
    monthYear,
    processor,
    generatedReportData,
    approvedRows,
    setApprovedRows,
    handleSaveChanges,
    setHasChanges,
    hasChanges,
}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    const processorHeaders = {
        type1: ['Merchant Id', 'Merchant Name', 'Transaction', 'Sales Amount', 'Income', 'Expenses', 'Net', 'BPS', '%', 'Agent Net', 'Branch ID'],
        type2: ['Merchant Id', 'Merchant Name', 'Payout Amount', 'Volume', 'Sales', 'Refunds', 'Reject Amount', 'Bank Split', 'Agent Net', 'Branch ID'],
        type3: ['Merchant Id', 'Merchant DBA', 'Payout Amount', 'Volume', 'Sales', 'Refunds', 'Reject Amount', 'Bank Split', 'Agent Net', 'Branch ID'],
        type4: ['Merchant Id', 'Merchant Name', 'Income', 'Expense', 'Net', '%', 'Agent Net', 'Branch ID']
    };

    const getProcessorType = (processor) => {
        return processorTypeMap[processor] || 'type1';
    };

    const handleApproveRow = (merchantId) => {
        const updatedApprovedRows = [...approvedRows];
        const index = updatedApprovedRows.findIndex(row => row['Merchant Id'] === merchantId);

        if (index === -1) {
            updatedApprovedRows.push({ 'Merchant Id': merchantId, approved: true });
        } else {
            updatedApprovedRows[index].approved = true;
        }

        setApprovedRows(updatedApprovedRows);
        setHasChanges(true);
    };

    const toggleRowSelection = (rowIndex) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(rowIndex)
                ? prevSelectedRows.filter((index) => index !== rowIndex)
                : [...prevSelectedRows, rowIndex]
        );
    };

    const handleBulkSelect = () => {
        const currentRows = reportData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
        const currentRowIndices = currentRows.map((_, idx) => idx + (currentPage - 1) * rowsPerPage);
        setSelectedRows((prevSelectedRows) =>
            currentRowIndices.every((idx) => prevSelectedRows.includes(idx))
                ? prevSelectedRows.filter((idx) => !currentRowIndices.includes(idx))
                : [...prevSelectedRows, ...currentRowIndices]
        );
    };

    const handleBulkApprove = () => {
        const minimalApprovedRows = reportData.map(({ 'Merchant Id': merchantId }) => ({
            'Merchant Id': merchantId,
            approved: true
        }));
        setApprovedRows(minimalApprovedRows);
        setHasChanges(true);
    };

    const renderTableHeaders = (type) => (
        <thead>
            <tr>
                <th>
                    <input
                        type="checkbox"
                        onChange={handleBulkSelect}
                        checked={reportData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
                            .every((item, idx) => selectedRows.includes(idx + (currentPage - 1) * rowsPerPage))
                        }
                    />
                </th>
                {processorHeaders[type].map((header, index) => (
                    <th key={index}>{header}</th>
                ))}
                <th>Approved</th>
            </tr>
        </thead>
    );

    const renderTableRows = (type, reportData) => {
        const currentRows = reportData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

        return currentRows.map((item, index) => {
            const globalIndex = index + (currentPage - 1) * rowsPerPage;
            const isSelected = selectedRows.includes(globalIndex);
            const isApproved = approvedRows.some(row => row['Merchant Id'] === item['Merchant Id'] && row.approved);

            return (
                <tr key={globalIndex} className={`report-row ${isApproved ? 'approved' : ''}`}>
                    <td>
                        <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={() => toggleRowSelection(globalIndex)}
                        />
                    </td>
                    {processorHeaders[type].map((header, i) => (
                        <td key={i}>
                            {header === 'Agent Net' && item['Bank Payout'] ? item['Bank Payout'] : item[header] || ''}
                        </td>
                    ))}
                    <td>{isApproved && <FaCheck />}</td>
                </tr>
            );
        });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const processorType = getProcessorType(processor);
    const reportData = generatedReportData[0]?.reportData || [];
    const totalPages = Math.ceil(reportData.length / rowsPerPage);

    return (
        <div className="report-viewer">
            <header className="header">
                <h1 className="header-title">
                    Processor: {processor}
                </h1>
                {hasChanges && (
                    <button onClick={handleSaveChanges} className="btn-save">
                        Save Changes
                    </button>
                )}
            </header>

            <div className="bulk-action-container">
                <button onClick={handleBulkApprove} className="bulk-action-btn">
                    Bulk Approve
                </button>
            </div>

            <div className="table-container">
                <table>
                    {renderTableHeaders(processorType)}
                    <tbody>
                        {renderTableRows(processorType, reportData)}
                    </tbody>
                </table>
            </div>

            <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="pagination-arrow pagination-btn"
                >
                    &laquo;
                </button>

                {currentPage > 1 && (
                    <button onClick={() => handlePageChange(currentPage - 1)} className="pagination-btn">
                        {currentPage - 1}
                    </button>
                )}

                <button className="pagination-btn active">{currentPage}</button>

                {currentPage < totalPages && (
                    <button onClick={() => handlePageChange(currentPage + 1)} className="pagination-btn">
                        {currentPage + 1}
                    </button>
                )}

                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="pagination-arrow pagination-btn"
                >
                    &raquo;
                </button>
            </div>
        </div>
    );
};

export default AgentReportViewer;
