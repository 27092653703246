import React, { useEffect, useState } from 'react';
import { getAllReports } from '../../api/reports.api.js';
import NeedsUpload from '../../components/dashboard/needs-upload/needs-uploaded.component.js';
import NeedsApproval from '../../components/dashboard/needs-approval/needs-approval.component.js';
import NeedsAudit from '../../components/dashboard/needs-audit/needs-audit.component.js';
import { useNavigate, useLocation } from 'react-router-dom';
import './dash.page.css';

const Dashboard = ({ organizationID: propOrganizationID, username, authToken }) => {
  const [fetchedReports, setFetchedReports] = useState([]); // All fetched reports
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Get organizationID from localStorage if not passed as a prop
  const organizationID = propOrganizationID || localStorage.getItem('organizationID');

  useEffect(() => {
    if (!organizationID) {
      console.error('No organizationID available.');
      return;
    }

    const fetchReports = async () => {
      setLoading(true);

      try {
        console.log(`Fetching all reports for organization: ${organizationID}...`);

        // Fetch reports using the API
        const reports = await getAllReports(organizationID, authToken);

        console.log('Fetched Reports:', reports);

        // Update the state with all fetched reports
        setFetchedReports(reports);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log('404 Error: No reports found.');
          setFetchedReports([]); // No reports found
        } else {
          console.error('Error fetching reports:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [authToken, organizationID, location.key]);

  return (
    <div className="dashboard-container">
      <h1>Welcome, {username}</h1>
      <h2>
        Reports for {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}
      </h2>

      {loading ? (
        <div className="loading">Loading reports...</div>
      ) : (
        <div className="dashboard-grid">
          {/* Pass all fetched reports to the components */}
          <div className="dashboard-card">
            <NeedsUpload reports={fetchedReports} />
          </div>

          <div className="dashboard-card">
            <NeedsAudit reports={fetchedReports} />
          </div>

          <div className="dashboard-card">
            <NeedsApproval reports={fetchedReports} authToken={authToken} organizationID={organizationID}  />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
