import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AgentReportViewer from '../../../components/reports/agent-report-viewer/agent-report-viewer.component';
import { generateAgentReport, createAgentReport, getAgentReportByMonth, updateReport } from '../../../api/reports.api.js';
import { getAgent } from '../../../api/agents.api';
import processorTypeMap from '../../../lib/typeMap.lib.js';
import './agent-report-viewer.page.css';

const AgentReportViewerPage = ({ organizationID, authToken }) => {
    const { agentID } = useParams();
    const [generatedReportData, setGeneratedReportData] = useState([]);
    const [activeProcessor, setActiveProcessor] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [agent, setAgent] = useState(null);
    const [approvedRows, setApprovedRows] = useState([]);
    const [dbReport, setDbReport] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const monthYear = searchParams.get('month');

    useEffect(() => {
        if (authToken && organizationID && agentID && monthYear) {
            fetchAgentDetailsAndReport();
            fetchSavedAgentReport();
        } else {
            setError('Missing required data to fetch reports');
        }
    }, [authToken, organizationID, agentID, monthYear]);

    useEffect(() => {
        console.log('Updated approved rows:', approvedRows);
    }, [approvedRows]); // Log whenever approvedRows is updated

    const fetchAgentDetailsAndReport = async () => {
        try {
            setLoading(true);
            setError(null);

            const agentResponse = await getAgent(organizationID, agentID, authToken);
            const agentData = agentResponse.data?.agent || null;
            setAgent(agentData);

            const reportResponse = await generateAgentReport(organizationID, agentID, monthYear, authToken);
            console.log('reportResponse', reportResponse);
            const agentReportData = reportResponse.data?.reportData || [];

            if (!agentReportData.length) {
                setError('No report data found for this month');
            } else {
                setGeneratedReportData(agentReportData);
                setActiveProcessor(agentReportData[0]?.processor || null);
            }
        } catch (err) {
            console.error('Error fetching agent report:', err);
            setError('Failed to fetch agent report');
        } finally {
            setLoading(false);
        }
    };


    const fetchSavedAgentReport = async () => {
        try {
            const savedReportResponse = await getAgentReportByMonth(organizationID, agentID, monthYear, authToken);
            const savedReportData = savedReportResponse?.data?.reportData || [];
            console.log('savedReportData', savedReportData);
            setApprovedRows(Array.isArray(savedReportData.reportData) ? savedReportData.reportData : []); // Ensure it's always an array
            setDbReport(savedReportResponse?.data); // Store the saved report data
        } catch (error) {
            console.error('Error fetching saved agent report:', error);
            setApprovedRows([]); // Fallback to empty array on error
        }
    };

    const handleTabChange = (processor) => {
        setActiveProcessor(processor);
    };

    const handleSaveChanges = async () => {
        try {
            const minimalApprovedRows = approvedRows.map(({ 'Merchant Id': merchantId, approved }) => ({
                'Merchant Id': merchantId,
                approved
            }));

            const allRowsApproved = generatedReportData.every((processorReport) =>
                processorReport.reportData.every((item) =>
                    minimalApprovedRows.some(approvedRow => approvedRow['Merchant Id'] === item['Merchant Id'] && approvedRow.approved)
                )
            );

            const reportData = {
                organizationID,
                agentID,
                month: monthYear,
                reportData: minimalApprovedRows,
                approved: allRowsApproved,
            };

            if (dbReport && dbReport.reportID) {
                await updateReport(dbReport.reportID, reportData, authToken);
                alert('Report updated successfully!');
            } else {
                await createAgentReport(organizationID, agentID, monthYear, reportData, authToken);
                alert('Report created successfully!');
            }

            fetchAgentDetailsAndReport();
            setHasChanges(false);
        } catch (error) {
            console.error('Error saving report:', error);
            alert('Error saving report.');
        }
    };

    const getProcessorType = (processor) => processorTypeMap[processor] || 'type1';

    const processorHeaders = {
        type1: ['Merchant Id', 'Merchant Name', 'Transaction', 'Sales Amount', 'Income', 'Expenses', 'Net', 'BPS', '%', 'Agent Net', 'Branch ID'],
        type2: ['Merchant Id', 'Merchant Name', 'Payout Amount', 'Volume', 'Sales', 'Refunds', 'Reject Amount', 'Bank Split', 'Bank Payout', 'Branch ID'],
        type3: ['Merchant Id', 'Merchant DBA', 'Payout Amount', 'Volume', 'Sales', 'Refunds', 'Reject Amount', 'Bank Split', 'Bank Payout', 'Branch ID'],
        type4: ['Merchant Id', 'Merchant Name', 'Income', 'Expenses', 'Net', '%', 'Agent Net', 'Branch ID']
    };

    const exportToCSV = () => {
        if (!generatedReportData.length) return;

        const csvRows = [];
        generatedReportData.forEach((processorReport) => {
            let totalVolume = 0;
            let totalSales = 0;
            let totalPayout = 0;
            let totalBankPayout = 0;
            let totalAgentNet = 0;

            const processorType = getProcessorType(processorReport.processor);
            csvRows.push([processorReport.processor]);
            csvRows.push(processorHeaders[processorType].join(','));

            processorReport.reportData.forEach((item) => {
                switch (processorType) {
                    case 'type1':
                        totalSales += parseFloat(item['Sales Amount']) || 0;
                        totalAgentNet += parseFloat(item['Agent Net']) || 0;
                        break;
                    case 'type2':
                    case 'type3':
                        totalVolume += parseFloat(item['Volume']) || 0;
                        totalSales += parseFloat(item['Sales']) || 0;
                        totalPayout += parseFloat(item['Payout Amount']) || 0;
                        totalBankPayout += parseFloat(item['Bank Payout']) || 0;
                        break;
                    case 'type4':
                        totalSales += parseFloat(item['Income']) || 0;
                        totalAgentNet += parseFloat(item['Agent Net']) || 0;
                        break;
                    default:
                        break;
                }

                const csvRow = processorHeaders[processorType].map((header) => item[header] || '');
                csvRows.push(csvRow.join(','));
            });

            csvRows.push([
                'Totals',
                '',
                '',
                totalPayout ? totalPayout.toFixed(2) : '',
                totalVolume ? totalVolume.toFixed(2) : '',
                totalSales.toFixed(2),
                '',
                '',
                '',
                totalBankPayout ? totalBankPayout.toFixed(2) : '',
                totalAgentNet ? totalAgentNet.toFixed(2) : '',
                ''
            ].join(','));

            csvRows.push('');
            csvRows.push('');
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `${agent.lName}_FullReport_${monthYear}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    if (loading) {
        return <div>Loading report data...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="agent-report-viewer-page">
            <h1>Agent Report for {monthYear}</h1>
            <button onClick={exportToCSV} className="btn-export">Export Full Report</button>

            <div className="tabs">
                {generatedReportData.map((processorReport) => (
                    <div
                        key={processorReport.processor}
                        className={`tab ${activeProcessor === processorReport.processor ? 'active' : ''}`}
                        onClick={() => handleTabChange(processorReport.processor)}
                    >
                        {processorReport.processor}
                    </div>
                ))}
            </div>

            <div className="tab-content">
                {activeProcessor && (
                    <AgentReportViewer
                        authToken={authToken}
                        organizationID={organizationID}
                        agentID={agentID}
                        monthYear={monthYear}
                        processor={activeProcessor}
                        generatedReportData={generatedReportData.filter((data) => data.processor === activeProcessor)}
                        approvedRows={approvedRows}
                        setApprovedRows={setApprovedRows}
                        handleSaveChanges={handleSaveChanges}
                        setHasChanges={setHasChanges}
                        hasChanges={hasChanges}
                    />
                )}
            </div>
        </div>
    );
};

export default AgentReportViewerPage;
